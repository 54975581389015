/* ====================== VARIABLE CSS ====================== */
:root {
  --header-height: 0.5rem;

  /* =========== Colors =========== */
  /* Color mode HSL(hue, saturation, lightness */
  /* --dark-blue: rgb(26, 31, 113); */
  --dark-blue: hsl(237, 62.6%, 27.3%);
  --soft-blue: rgb(70, 73, 140);
  --normal-grey: rgb(228, 224, 223);
  --dark-green: rgb(52, 152, 36);
  --soft-green: rgb(142, 198, 63);
  --fresh-orange: rgb(246, 134, 34);
  --light-yellow: rgb(246, 199, 35);

  /* --first-color: hsl(228, 85%, 37%); */
  --first-color-alt: hsl(228, 66%, 47%);
  --first-color-light: hsl(228, 62%, 59%);
  --fist-color-lighten: hsl(228, 100%, 97%);
  --second-color: hsl(25, 83%, 53%);
  --title-color: hsl(228, 57%, 28%);
  --text-color: hsl(228, 15%, 50%);
  --text-color-light: hsl(228, 12%, 75%);
  --border-color: hsl(228, 99%, 98%);
  --body-color: #fff;
  --container-color: #fff;

  /* =========== Typography =========== */
  /* .5rem = 8px / 1rem = 16px */
  --biggest-font-size: 2.25rem;
  --h1-font-size: 1.5rem;
  --h2-font-size: 1.25rem;
  --h3-font-size: 1rem;
  --normal-font-size: 0.938rem;
  --small-font-size: 0.813rem;
  --smaller-font-size: 0.75rem;

  /* =========== Font Weight =========== */
  --font-medium: 500;
  --font-semi-bold: 600;

  /* =========== Z-index =========== */
  --z-tooltip: 10;
  --z-fixed: 100;

  /* =========== Shadow =========== */
  --box-shadow: 0 8px 24px hsla(228, 66%, 45%, 0.15);
}

/* Responsive Typography */
@media screen and (min-width: 1024px) {
  :root {
    --biggest-font-size: 4rem;
    --h1-font-size: 2.25rem;
    --h2-font-size: 1.5rem;
    --h3-font-size: 1.25rem;
    --normal-font-size: 1rem;
    --small-font-size: 0.875rem;
    --smaller-font-size: 0.813rem;
  }
}

@font-face {
  font-family: 'Myriad-Regular';
  src: local('MYRIADPRO-REGULAR'),
    url('./fonts/MYRIADPRO-REGULAR.OTF') format('truetype');
}

@font-face {
  font-family: 'Myriad-Bold';
  src: local('MYRIADPRO-SEMIBOLD'),
    url('./fonts/MYRIADPRO-SEMIBOLD.OTF') format('truetype');
}

@font-face {
  font-family: 'Arial-Custom-Bold';
  src: local('ARLRDBD'), url('./fonts/ARLRDBD.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins-Bold';
  src: local('Poppins-Bold'), url('./fonts/Poppins-Bold.otf') format('truetype');
}

/* ====================== BASE ====================== */
* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html {
  scroll-behavior: smooth;
  overflow-x: hidden;
}

body {
  font-size: var(--normal-font-size);
  background-color: var(--body-color);
  overflow-y: clip;
}

h1,
h2,
h3 {
  font-weight: var(--font-semi-bold);
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
}

img {
  max-width: 100%;
  height: auto;
}

input,
button {
  outline: none;
  border: none;
}

/* ====================== REUSABLE CSS CLASSES ====================== */
.container {
  margin-left: 1.5rem;
  margin-right: 1.5rem;
}

.grid {
  display: grid;
}

.section {
  padding: 4.5rem 0 2rem;
}

.section__title {
  font-size: var(--h2-font-size);
  margin-bottom: 1rem;
}

.section__title span {
  color: var(--second-color);
}

.section__subtitle {
  display: block;
  font-size: var(--small-font-size);
  color: var(--second-color);
}

/** For small device */
@media screen and (max-width: 480px) {
  html,
  body {
    overflow-x: hidden;
    position: relative;
  }
}

/* For large devices */
@media screen and (min-width: 1023px) {
  .container {
    margin-right: 4.5rem;
    margin-left: 4.5rem;
  }

  .section {
    padding: 7.5rem 0 1rem;
  }
  .section__title {
    font-size: 2.25rem;
  }
  .section__subtitle {
    font-size: var(--normal-font-size);
  }
}

/* For medium devices */
@media (min-width: 641px) and (max-width: 768px) {
  .section {
    padding: 8rem 0 2rem;
  }
}
