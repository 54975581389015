/* Footer container start here */
.footer__container {
  position: relative;
  height: auto;
  background-color: var(--dark-blue);
}
/* Footer container end here */

/* Footer text start here */
.footer__text-wrapper {
  display: inline-flex;
  width: 65vw;
  color: var(--body-color);
  padding: 2rem 0 2rem 4.5rem;
  text-align: left;
  gap: 3rem;
  font-family: 'Myriad-Regular';
}
.footer__turana h3 {
  font-family: 'Poppins-Bold';
  font-size: 16px;
  margin-bottom: 2rem;
}
.footer__turana p {
  font-size: 14px;
  line-height: 1.6;
}
.footer__turana {
  width: 50vw;
  padding: 0.75rem;
}
.footer__sosmed {
  display: none;
}
.footer__info {
  border-left: 1px solid #fff;
  width: 50vw;
  padding: 0.75rem 0.75rem 0.75rem 1rem;
  margin-left: 0.75rem;
}
.footer__sosmed h3,
.footer__info h3 {
  font-family: 'Poppins-Bold';
  font-size: 14px;
}
.footer__info-wrapper {
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
.footer__info p {
  font-size: 14px;
  font-weight: bold;
}
.footer__icon {
  display: inline-flex;
}
.footer__icon p {
  margin-left: 0.5rem;
}
/* Footer text emd here */

/* Footer image start here */
.footer__image img {
  position: absolute;
  width: 440px;
  height: auto;
  bottom: -0.8rem;
  right: 0;
}
/* Footer image end here */

/* Copyright start here */
.copyright {
  background-color: var(--soft-blue);
  color: var(--body-color);
  padding: 0.5rem;
  text-align: center;
  font-size: 14px;
  word-spacing: 1.5px;
}
.copyright__icon {
  font-size: 8px !important;
  border-radius: 50%;
  padding: 0 1.5px;
  border: 1px solid var(--body-color);
  vertical-align: middle;
}
/* Copyright end here */

/* For medium devices */
@media screen and (min-width: 767px) and (max-width: 1200px) {
  .footer__image img {
    display: none;
  }
  .footer__text-wrapper {
    width: 100%;
    padding-right: 1.5rem;
  }
}
/* For small devices */
@media screen and (max-width: 767px) {
  .footer__container {
    padding-bottom: 4.15rem;
  }
  .footer__image img {
    display: none;
  }
  .footer__text-wrapper {
    width: 100%;
    padding: 0 1.5rem 0.75rem;
    gap: 0;
    display: flex;
    flex-direction: column;
  }
  .footer__info {
    width: 100%;
    border-left: none;
    padding: 0;
    margin-left: 0;
    margin-bottom: 0.5rem;
  }
  .footer__turana {
    width: 100%;
    padding: 1.5rem 0 0.75rem;
  }
  .footer__turana h3 {
    font-size: 14px;
    margin-bottom: 0.5rem;
  }
  .footer__turana p {
    font-size: 12px;
    color: #e1d9d1;
  }
  .footer__icon svg {
    height: 14px;
  }
  .footer__sosmed {
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;
  }
  .footer__sosmed h3 {
    font-size: 14px;
  }
  .footer__info-wrapper {
    margin-top: 0.5rem;
  }
  .footer__icon p {
    color: #e1d9d1;
    font-size: 12px;
  }
  .footer_sosmed-icon {
    margin: 0.2rem 0 0 1.5rem;
    display: flex;
    gap: 1rem;
  }
  .footer_sosmed-icon img {
    height: 14px;
  }
  .copyright {
    font-size: 10px;
    margin-bottom: -11px;
  }
}
