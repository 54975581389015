.fixed__sosmed-container {
  position: fixed;
  z-index: var(--z-fixed);
  right: 2%;
  bottom: 2%;
  transition: 0.7s;
}
.fixed__sosmed-container-bottom {
  position: fixed;
  z-index: var(--z-fixed);
  right: 2%;
  bottom: 30%;
  transition: 0.7s;
}
.fixed__sosmed-icon {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
.fixed__sosmed-icon img {
  width: 60px;
}
/* Animation on hover start here */
.companies__icon:hover {
  cursor: pointer;
  /* Start the shake animation and make the animation last for 0.5 seconds */
  -webkit-animation: shake 0.5s;
  animation: shake 0.5s;

  /* When the animation is finished, start again */
  animation-iteration-count: infinite;
}
@keyframes shake {
  0% {
    transform: translate(1px, 1px) rotate(0deg);
  }
  20% {
    transform: translate(3px, 2px) rotate(1deg);
  }
  40% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }
  60% {
    transform: translate(3px, 1px) rotate(-1deg);
  }
  80% {
    transform: translate(1px, 2px) rotate(0deg);
  }
  100% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
}
/* Animation on hover end here */

/** For small device */
@media screen and (max-width: 767px) {
  .fixed__sosmed-container {
    display: none;
  }
  .fixed__sosmed-container-bottom {
    display: none;
  }
}
