.modal {
  display: flex;
  bottom: 0;
  left: 0;
  padding: 0;
  overflow: hidden;
  position: absolute;
  pointer-events: none;
  right: 0;
  top: 0;
  width: 100%;
}
.modal.modal--visible {
  pointer-events: visible;
  overflow: hidden;
  position: fixed;
  height: 100vh;
  z-index: 999;
}
.modal .modal__body {
  transform: translate3d(0, 100%, 0);
}
.modal.modal--visible .modal__body {
  transform: translate3d(0, 0, 0);
}
.modal .modal__mask {
  display: none;
}
.modal.modal--visible .modal__mask {
  display: block;
}
.modal__mask {
  background: rgba(0, 0, 0, 0.4);
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 999;
  overflow: hidden;
  transform: translate3d(0, 0, 0);
  width: 100%;
}
.modal__body {
  background: #fff;
  border-radius: 6px 6px 0 0;
  bottom: 0;
  padding: 1rem 1rem 1.5rem;
  height: 100vh;
  position: fixed;
  width: 100%;
  z-index: 999;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.modal__close-btn {
  width: 100%;
  margin-right: -1rem;
  display: flex;
  justify-content: flex-end;
  position: relative;
}
.modal__close-btn span {
  cursor: pointer;
}

@media screen and (min-width: 768px) {
  .modal {
    justify-content: center;
  }
  .modal__body {
    border-radius: 6px;
    width: 65%;
    bottom: auto;
    top: 30vh;
    padding: 2rem;
    position: fixed;
  }
}

@media screen and (min-width: 1024px) {
  .modal__body {
    width: 50%;
    position: fixed;
  }
}
