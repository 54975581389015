/** ========= Nav logo start here ========= */
.nav__container {
  position: fixed;
  width: 100%;
  display: inline-flex;
  align-items: center;
  padding: 1rem 0 1rem 4.5rem;
  z-index: var(--z-fixed);
  background: var(--dark-blue);
  top: 0;
  font-family: 'Myriad-Regular';
}
.nav__logo-round {
  display: flex;
  align-items: center;
  background-color: var(--container-color);
  width: 8.5rem;
  height: 8.5rem;
  border-radius: 50%;
  border: none;
  z-index: var(--z-fixed);
}
.nav__logo-small {
  /* Hide for medium & large devices */
  display: none;
}
.nav__logo img {
  padding: 0.25rem;
  width: 10.5rem;
  height: auto;
}
/** For small device */
@media screen and (max-width: 767px) {
  /* Navbar move to bottom  */
  .nav__container {
    z-index: 11;
    padding: 0;
    background: transparent;
    bottom: 0;
    left: 0;
    position: relative;
  }
  .nav__logo-round {
    display: none;
  }
  .nav__logo-small {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: var(--container-color);
    position: fixed;
    width: 100%;
    box-shadow: var(--box-shadow);
    z-index: var(--z-fixed);
  }
  .nav__logo-small img {
    padding: 0.2rem;
    width: 9rem;
    margin-bottom: -0.32rem;
  }
  .nav__small-sosmed {
    margin-right: 1rem;
  }
  .nav__small-sosmed p {
    font-family: 'Arial-Custom-Bold';
    font-size: 14px;
    text-align: center;
    font-weight: bold;
    color: black;
  }
  .nav__sosmed-wrapper {
    display: flex;
    gap: 0.5rem;
    padding: 0.25rem 0;
  }
  .nav__sosmed-wrapper img {
    width: 35px;
    height: 35px;
  }
}
/** For medium & large device */
@media screen and (min-width: 767px) and (max-width: 1023px) {
  .nav__container {
    padding-left: 1.5rem;
  }
}
/** ========= Nav logo end here ========= */

/** ========= Nav menu list start here ========= */
.nav__menu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: -8rem;
  padding-left: 10.5rem;
  width: 100%;
  white-space: nowrap;
  background-color: var(--soft-blue);
}
.nav__menu-left {
  display: flex;
  align-items: center;
}
.nav__menu-left p {
  color: var(--body-color);
  font-weight: bold;
  font-size: 16px;
  font-family: 'Arial-Custom-Bold';
}
.nav__wrapper-icon {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  margin-left: 1.5rem;
}
.nav__wrapper-icon img:nth-child(1) {
  margin-right: -0.2rem;
}
.nav__wrapper-icon img {
  max-width: 18px;
}
.nav__wrapper-icon img:hover {
  cursor: pointer;
}
.nav__list {
  display: flex;
  align-items: center;
  padding: 0.75rem 7.5rem;
  gap: 1.8rem;
}
.nav__item div:hover {
  cursor: pointer;
}
.nav__item .nav__link span {
  color: #e1d9d1;
  font-size: 16px;
  text-transform: capitalize;
  font-family: 'Arial-Custom-Bold';
}
.nav__item .nav__link.selected span {
  color: var(--body-color);
  font-family: 'Arial-Custom-Bold';
  font-size: 20px;
}
/** For small devices */
@media screen and (max-width: 767px) {
  .nav__menu {
    position: fixed;
    width: 100%;
    padding: 5px;
    margin-left: 0;
    bottom: 0;
    border-top: 2px solid #949494;
    background-color: var(--body-color);
    box-shadow: var(--box-shadow);
  }
  .nav__menu-left {
    display: none;
  }
  .nav__link {
    margin-top: -3px;
  }
  .nav__list {
    display: flex;
    padding: 0;
    text-align: center;
    background: transparent;
    justify-content: center;
    width: 100%;
    gap: 2.5rem;
  }
  .nav__item {
    display: flex;
    flex-direction: column;
  }
  .nav__item .nav__link span {
    color: #949494;
    font-size: 12px;
  }
  .nav__item .nav__link.selected span {
    color: var(--dark-blue);
    font-size: 12px;
    font-family: 'Arial-Custom-Bold';
  }
}
/** For medium device */
@media screen and (min-width: 767px) and (max-width: 1200px) {
  .nav__icon {
    display: none;
  }
  .nav__menu {
    padding-left: 9.8rem;
  }
  .nav__menu-left p {
    font-size: 14px;
  }
  .nav__list {
    padding: 0.75rem 1.5rem;
    gap: 1rem;
  }
  .nav__item .nav__link span {
    font-size: 12px;
  }
  .nav__item .nav__link.selected span {
    font-size: 14px;
  }
}
/** For large device */
@media screen and (min-width: 1201px) {
  .nav__container {
    padding-left: 9.5rem;
  }
  .nav__icon {
    display: none;
  }
}
/** ========= Nav menu list end here ========= */
