.loader__container {
  margin: 11.5rem 0 0;
  height: 100vh;
  display: flex;
  justify-content: center;
}
.loader {
  margin: auto;
  border: 20px solid #eaf0f6;
  border-radius: 50%;
  border-top: 20px solid var(--dark-blue);
  width: 120px;
  height: 120px;
  animation: spinner 1.6s linear infinite;
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* For medium devices */
@media screen and (min-width: 767px) and (max-width: 1023px) {
  .loader__container {
    margin-top: 1rem;
  }
}

/* For small devices */
@media screen and (max-width: 767px) {
  .loader {
    width: 80px;
    height: 80px;
  }
  .loader__container {
    margin-top: 0.5rem;
  }
}
